import { Fragment } from "react";

import { EventTitle } from "./components/event-title/event-title";
import { EventDescription } from "./components/event-description";

import { EventState, SelectedSessionIdState } from "states";
import { useRecoilState, useRecoilValue } from "recoil";
import { EventCamera } from "./components/event-camera";
import { DateTime } from "luxon";
import { AttendeeLists, MatchedProfile, PendingProfiles } from "./components";

import "./events.scss";
import { SessionModal } from "session-info";
import { CircularProgress } from "@mui/material";

export const getDateTime = (date: string) => {
  const formattedDate = DateTime.fromISO(date).toFormat(
    "EEEE, LLLL dd · h:mma"
  );
  return formattedDate;
};

export const EventHeader = () => {
  const event = useRecoilValue(EventState);

  return (
    <div className="event-wrapper__header">
      <div>
        <EventTitle title={event?.name} />
        <EventDescription
          description={event?.date ? getDateTime(event?.date) : ""}
        />
      </div>
      {/* <div className="d-flex gp-8 align-item-center">
        <div>
          <EventTitle title="Prateek Singh" />
          <EventDescription description="prateek.singh@gmail.com" />
        </div>
        <img
          className="profile-img"
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0KehqXBrMLd32HsfjDoaq098WeNA0b3g_2A&s"
          alt="profile"
        />
      </div> */}
    </div>
  );
};

export const Events = () => {
  const [selectedSessionId, setSelectedSessionId] = useRecoilState(SelectedSessionIdState);
  const event = useRecoilValue(EventState);

  return (
    <Fragment>
      {
        event ? <div className="event-wrapper">
        { 
          selectedSessionId ? <SessionModal
            setOpen={() => setSelectedSessionId(null)}
            open={!!selectedSessionId} 
            sessionId={selectedSessionId} 
          /> : null
        }
        {/* Event Header */}
        <EventHeader />

        {/* Event Body */}
        <div className="event-wrapper__body">
          {/* Left Section - Attendee List */}
          <div className="event-wrapper__left">
            <AttendeeLists />
          </div>

          {/* Center Section - Matched Profiles */}
          <div className="event-wrapper__center">
            <div className="d-flex direction-column relative">
              <EventCamera />

              <MatchedProfile />
            </div>
          </div>

          {/* Right Section - Unregistered Profiles */}
          <div className="event-wrapper__right">
            <PendingProfiles />
          </div>
        </div>
      </div> : <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }} className="event-wrapper">
      <CircularProgress />
      </div>
      }
      
    </Fragment>
  );
};
