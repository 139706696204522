import * as vision from "@mediapipe/tasks-vision";
import * as cocoSsd from '@tensorflow-models/coco-ssd';
import { useCallback, useEffect, useRef } from "react";
import { defaultGestureRecognizerResults } from "./utils";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { EventState, EventTypeState, ModelLoadedState, QrShowedState, ShowPalmMatchState, VideoHeightState } from "../states";
import FaceMetaInfo from "./face-meta";
import { Memory } from "../memory";
import { worker } from "../App";
import { QRCode } from "jsqr";
import QrScanner from "./scanner";

let lastScanTime = 0;
const scanInterval = 100; // Scan every 500ms

const { FaceLandmarker, FilesetResolver, DrawingUtils, GestureRecognizer } =
  vision;

function distance2D(x1: number, y1: number, x2: number, y2: number) {
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
}

function getRandomIndices(array: any[], indicesLength = 3) {
  if (array.length < 3) {
    throw new Error("Array should have at least 3 elements");
  }

  let indices: number[] = [];
  while (indices.length < indicesLength) {
    let randomIndex = Math.floor(Math.random() * array.length);
    if (!indices.includes(randomIndex)) {
      indices.push(randomIndex);
    }
  }
  return Array.from(indices).map(index => array[index]);
}

function findLowestValueInObjects(array: any[], className: string, key: string) {
  if (array.length === 0) {
    throw new Error("Array is empty");
  }

  const filteredArray = array.filter(a => a.class === className);

  return filteredArray.reduce((lowest, item) => {
    if (item[key] < lowest[key]) {
      return item;
    }
    return lowest;
  }, filteredArray[0]);
}

function isPersonInsideCellPhone(cellPhoneBBox: any[], personBBox: any[], videoWidth: number) {
  // Unpack the bounding boxes
  const [cellPhoneX, cellPhoneY, cellPhoneW, cellPhoneH] = cellPhoneBBox;
  const [personX, personY, personW, personH] = personBBox;

  const cX = videoWidth - cellPhoneX;
  const pX = videoWidth - personX;

  console.log('a', {
    cX,
    pX
  })
  // Check if the person bounding box is inside the cell phone bounding box
  if (pX >= cX &&
      personY >= cellPhoneY &&
      pX + personW <= cX + cellPhoneW &&
      personY + personH <= cellPhoneY + cellPhoneH) {
      return true;
  }

  return false;
}

interface IStyle {
  lineColor: string;
  lineWidth: number;
}

const style = {
  lineColor: "#fff",
  lineWidth: 8,
};

interface IBoundingBox {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
}

let isStarted = false

export const useFace = () => {
  const faceLandmarker = useRef<vision.FaceLandmarker>();
  const gestureRecognizer = useRef<vision.GestureRecognizer>();
  const webcamRunning = useRef(false);
  const videoWidth = useRef<number>()
  const palmMatch = useRef<boolean>(true)
  const lastVideoTime = useRef(-1);
  const faceLandmarkerResults = useRef<vision.FaceLandmarkerResult>();
  const gestureRecognizerResults = useRef<vision.GestureRecognizerResult>();
  const lastQrCodeResults = useRef<QRCode | null>();
  const qrCodeResults = useRef<QRCode | null>();
  const qrDetect = useRef(true);
  const qrDetectionTimeout = useRef<number>();

  const predictionsResults = useRef<cocoSsd.DetectedObject[]>();
  const canvasElement = useRef<HTMLCanvasElement>();
  const videoElement = useRef<HTMLVideoElement>();
  const videoBlendShapes = useRef<HTMLElement>();
  const setShowPalmMatch = useSetRecoilState(ShowPalmMatchState);
  const framesArray = useRef<ImageData[]>([]);
  const frame_limit = useRef(Number(Memory.frames_limit || localStorage.getItem("frame_limit") || 5));
  const frames = useRef(0);
  const detectData = useRef<cocoSsd.DetectedObject[]>([]);
  const setVideoHeight = useSetRecoilState(VideoHeightState);
  const setQrShowed = useSetRecoilState(QrShowedState);
  const setModelLoaded = useSetRecoilState(ModelLoadedState);
  
  const isLeftRef = useRef(false);
  const offlineCanvas = useRef<HTMLCanvasElement>(document.createElement('canvas'));
  const event = useRecoilValue(EventState);

  // Draw plus sign parameters
  const plusSize = 20;
  const handOffset = 60;
  const callbackFunc = useRef<Function>();

  // Check if webcam access is supported.
  const hasGetUserMedia = () => {
    return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
  };


  useEffect(() => {
    worker.onmessage = (e) => {
      const qrData = e.data?.data;
      qrCodeResults.current = qrData;
    };
  }, [])

  const drawBlendShapes = useCallback(
    (el: HTMLElement, blendShapes: vision.Classifications[]) => {
      if (!blendShapes.length) {
        return;
      }

      let htmlMaker = "";
      blendShapes[0].categories.forEach((shape) => {
        htmlMaker += blendShapeHtml(shape);
      });

      let results = gestureRecognizerResults.current;

      if (results?.gestures.length === 0) {
        results = defaultGestureRecognizerResults;
      }

      results?.gestures.forEach((gesture, index) => {
        const shape = gesture?.[0];
        let handedness = results?.handednesses?.[index]?.[0]?.displayName;
        if (handedness) {
          if (/right/gi.test(handedness)) {
            handedness = "Left";
          } else if (/left/gi.test(handedness)) {
            handedness = "Right";
          }
        }
        htmlMaker += blendShapeHtml(shape, handedness);
      });
      el.innerHTML = htmlMaker;
    },
    []
  );

  const drawPalmImage = useCallback(
    (landmarks: vision.NormalizedLandmark[]) => {
      if (!videoElement.current) {
        return;
      }

      if (!canvasElement.current) {
        return;
      }

      let handedness =
        gestureRecognizerResults.current?.handednesses?.[0]?.[0]?.displayName;

      const Points = {
        pinkyPip: 18,
        thumbTip: 6,
      };

      const isLeftHand = /right/gi.test(handedness ?? "");
      if (isLeftHand) {
        isLeftRef.current = true;
        // handedness = 'Left'
        Points.pinkyPip = 6;
        Points.thumbTip = 18;
      } else {
        isLeftRef.current = false;
      }

      const palmImg = document.getElementById("palm") as HTMLImageElement;

      const canvasWidth = canvasElement.current.width;
      const canvasHeight = canvasElement.current.height;

      let pinkyPipX = landmarks[Points.pinkyPip].x * canvasWidth - handOffset;
      let pinkyPipY = landmarks[Points.pinkyPip].y * canvasHeight - handOffset;

      let thumbTipX = landmarks[Points.thumbTip].x * canvasWidth + handOffset;
      let thumbTipY = landmarks[Points.thumbTip].y * canvasHeight + handOffset;

      let middleFingerPipX = landmarks[10].x * canvasWidth;
      let middleFingerPipY = landmarks[10].y * canvasHeight;

      let wristX = landmarks[0].x * canvasWidth + handOffset;
      let wristY = landmarks[0].y * canvasHeight + handOffset;

      const leftHandOffset = 80;
      if (isLeftHand) {
        pinkyPipX -= leftHandOffset;
        pinkyPipY -= leftHandOffset;
        thumbTipX -= leftHandOffset;
        thumbTipY -= leftHandOffset;
        wristX += leftHandOffset;
        wristY += leftHandOffset;
        // middleFingerPipX -= leftHandOffset;
        // middleFingerPipY -= leftHandOffset;
      }

      const distanceWidth = distance2D(
        pinkyPipX,
        pinkyPipY,
        thumbTipX,
        thumbTipY
      );
      const distanceHeight = distance2D(
        middleFingerPipX,
        middleFingerPipY,
        wristX,
        wristY
      );

      // Draw bounding box
      const bboxWidth = distanceWidth;
      const bboxHeight = distanceHeight;

      const offScreenCanvas = document.createElement("canvas");
      offScreenCanvas.width = bboxWidth;
      offScreenCanvas.height = bboxHeight;
      const offCtx = offScreenCanvas.getContext("2d");

      if (offScreenCanvas.width && offScreenCanvas.height) {
        // Draw the cropped area onto the off-screen canvas
        offCtx?.drawImage(
          videoElement.current,
          pinkyPipX,
          pinkyPipY,
          bboxWidth,
          bboxHeight,
          0,
          0,
          bboxWidth,
          bboxHeight
        );
        // Convert the off-screen canvas to an image URL and set it as the source of the <img> tag
        palmImg.style.display = "block";
        palmImg.src = offScreenCanvas.toDataURL();
      } else {
        palmImg.style.display = "none";
      }
    },
    []
  );

  const drawPalmCircle = useCallback(
    (landmarks: vision.NormalizedLandmark[], style: IStyle) => {
      const canvasCtx = canvasElement.current?.getContext("2d");

      if (!canvasCtx || !canvasElement.current) {
        return;
      }

      const canvasWidth = canvasElement.current.width;
      const canvasHeight = canvasElement.current.height;

      const wristX = landmarks[0].x * canvasWidth;
      const wristY = landmarks[0].y * canvasHeight;

      const topPalmStartX = landmarks[9].x * canvasWidth;
      const topPalmStartY = landmarks[9].y * canvasHeight;

      const palmCenterX = (topPalmStartX + wristX) / 2;
      const palmCenterY = (topPalmStartY + wristY) / 2;

      const centerX = canvasElement.current.width / 2;
      const centerY = canvasElement.current.height / 2;

      const mainRadius = distance2D(centerX, 0, centerX, centerY) / 2;
      const radius = distance2D(wristX, wristY, palmCenterX, palmCenterY);

      const openPalm = gestureRecognizerResults.current?.gestures.find(
        (gesture) => {
          return gesture?.[0].categoryName === "Open_Palm";
        }
      );

      const offset = mainRadius / 2;
      const checkX =
        palmCenterX >= centerX - offset && palmCenterX <= centerX + offset;
      const checkY =
        palmCenterY >= centerY - offset && palmCenterY <= centerY + offset;

      const checkRadius =
        radius <= mainRadius * 1.2 && radius >= mainRadius / 1.2;

      if (checkX && checkY && openPalm && checkRadius) {
        style.lineColor = "#318CE7";
        drawPalmImage(landmarks);

        // @need to add flip
        // Set the text color
        canvasCtx.fillStyle = "#fff";
        // Set the font size and family
        canvasCtx.font = "bold 45px Arial";
        canvasCtx.save();
        canvasCtx.scale(-1, 1);
        const number = localStorage.getItem("phone") ?? "";
        let name = "N/A";

        if (/4157676179/gi.test(number)) {
          name = "Austin Trombley";
        } else if (/6124011819/gi.test(number)) {
          name = "Johny Whitaker";
        }
        if (isLeftRef.current) {
          name = "N/A";
        }
        const textWidth = canvasCtx.measureText(name).width;
        // Draw the text
        canvasCtx.fillText(name, -wristX - textWidth + 40, wristY + 80);
        canvasCtx.restore();
      } else {
        style.lineColor = "#fff";
        const palmImg = document.getElementById("palm") as HTMLImageElement;
        palmImg.style.display = "none";
        palmImg.src = "";
      }

      canvasCtx.beginPath();
      canvasCtx.arc(palmCenterX, palmCenterY, radius, 0, 2 * Math.PI, false);
      canvasCtx.lineWidth = 2; // Plus sign line width
      canvasCtx.strokeStyle = style.lineColor; // Plus sign color
      canvasCtx.stroke();
    },
    []
  );

  const sendImageToSocket = useCallback((boundingBox: IBoundingBox) => {
    if (!Memory.useSocket) {
      return;
    }

    const {externalDevice, isPersonScoreLow} = Memory.detection;

    if (externalDevice || isPersonScoreLow) {
      frames.current = 0;
      return;
    }

    const offScreenCanvas = document.createElement("canvas");
    const canvasWidth = videoElement.current?.videoWidth;
    const canvasHeight = videoElement.current?.videoHeight;
    const offCtx = offScreenCanvas.getContext("2d");
    
    if (
      offCtx &&
      videoElement.current &&
      canvasWidth &&
      canvasHeight
    ) {
        // offCtx.save();
        // offCtx.scale(-1, 1);

        offScreenCanvas.width = canvasWidth;
        offScreenCanvas.height = canvasHeight;
  
        // const { minX, minY, maxX, maxY } = boundingBox;
        offCtx.drawImage(
          videoElement.current,
          0,
          0,
          canvasWidth,
          canvasHeight,
          // 0,
          // 0,
          // maxX - minX,
          // maxY - minY
        );

        // const image = offScreenCanvas.toDataURL('image/jpeg', 0.8);
        // emit(Memory.sessionId, image);
        
        // console.log(image)
        // offCtx.restore();
        const sourceImage = offCtx.getImageData(0, 0, offScreenCanvas.width, offScreenCanvas.height);
        framesArray.current.push(sourceImage);

        if (frames.current !== frame_limit.current) {
          frames.current = frames.current + 1;
          return;
        }

        getRandomIndices(framesArray.current, 5).forEach(sourceImage => {
          worker.postMessage({ sourceImage, targetImage: Memory.selfieBinary });
        })

        framesArray.current = [];
        frames.current = 0;
      }
    },
    []
  );

  const drawPlaceholder = useCallback((style: IStyle) => {
    const canvasCtx = canvasElement.current?.getContext("2d");

    if (!canvasCtx || !canvasElement.current) {
      return;
    }
    const { lineColor, lineWidth } = style;
    // Circle parameters
    const centerX = canvasElement.current.width / 2;
    const centerY = canvasElement.current.height / 2;
    const radius = distance2D(centerX, 0, centerX, centerY) / 2;
    const borderWidth = lineWidth;

    // Draw circle
    canvasCtx.beginPath();
    canvasCtx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
    canvasCtx.lineWidth = borderWidth;
    canvasCtx.strokeStyle = lineColor;
    canvasCtx.stroke();

    // // Draw horizontal line of plus
    // canvasCtx.beginPath();
    // canvasCtx.moveTo(centerX - plusSize / 2, centerY);
    // canvasCtx.lineTo(centerX + plusSize / 2, centerY);
    // canvasCtx.lineWidth = style.lineWidth - 2; // Plus sign line width
    // canvasCtx.strokeStyle = style.lineColor; // Plus sign color
    // canvasCtx.stroke();

    // // Draw vertical line of plus
    // canvasCtx.beginPath();
    // canvasCtx.moveTo(centerX, centerY - plusSize / 2);
    // canvasCtx.lineTo(centerX, centerY + plusSize / 2);
    // canvasCtx.stroke();
  }, []);

  const blendShapeHtml = useCallback(
    (shape: vision.Category, handedness?: string) => {
      const handednessText = handedness ? `${handedness} : ` : "";
      return `
        <li class="blend-shapes-item">
        <span class="blend-shapes-label">${handednessText}${
        shape.displayName || shape.categoryName
      }</span>
        <span class="blend-shapes-value" style="width: calc(${
          +shape.score * 100
        }% - 120px)">${(+shape.score).toFixed(4)}</span>
      </li>
      `;
    },
    []
  );

  const drawSpoof = (text: string, canvasCtx: CanvasRenderingContext2D, box: IBoundingBox) => {
    const textSoof = text;

    if (/REAL|SPOOF/.test(textSoof)) {
      const { minX, minY, maxX, maxY } = box
      // Draw the bounding box
      canvasCtx.save();
      canvasCtx.strokeStyle = textSoof === "SPOOF" ? "red" : "green";
      canvasCtx.lineWidth = 8;
      canvasCtx.strokeRect(minX, minY, maxX - minX, maxY - minY);

      const score = (100 - (FaceMetaInfo.getValue("score") as number) ?? 0).toFixed(0) + "%";

      const fontSize = 35; // Adjust font size as needed
      const fontFamily = "Arial";
      canvasCtx.font = `bold ${fontSize}px ${fontFamily}`;
      const scoreWidth = canvasCtx.measureText(score as string).width;

      // Calculate text position above the bounding box, aligned with the right side
      const textX = maxX; // Align with the right side of the bounding box
      const textY = minY - 10; // Adjust the Y-coordinate to be above the bounding box

      // Save the canvas state
      canvasCtx.save();

      // Flip the context horizontally
      canvasCtx.scale(-1, 1);

      // Draw the background rectangle for the entire top line of the bounding box
      canvasCtx.fillStyle = textSoof === "SPOOF" ? "red" : "green"; // Set background color
      const backgroundPadding = 5; // Padding around the text
      const boundingBox = {
        x: -maxX - backgroundPadding,
        y: textY - fontSize,
        w: maxX - minX + 2 * backgroundPadding - (scoreWidth + 5),
        h: fontSize + 2 * backgroundPadding
      }
      canvasCtx.fillRect(
        boundingBox.x,
        boundingBox.y,
        boundingBox.w,
        boundingBox.h
      ); // Draw background rectangle

      // Draw the text
      canvasCtx.fillStyle = "white"; // Set text color
      canvasCtx.fillText(textSoof as string, -textX + 50, textY); // Draw the text

      canvasCtx.font = "600 40px Arial";
      canvasCtx.fillStyle = textSoof === "SPOOF" ? "red" : "white"; // Set text color
      canvasCtx.fillText(score, -minX - scoreWidth, textY); // Draw the text

      // Restore the canvas state
      canvasCtx.restore();
      // setWebrtcSessionId(Memory.sessionId);
    }
  }

   //Function to draw the curved corner bounding box
   const drawQrBoundingBox = useCallback((context: CanvasRenderingContext2D) => {

    const qrCode = qrCodeResults.current;

    if (!qrCode) {
      return;
    }

    const isValid = event?.users.find(user => user.sessionId === qrCode.data)

    // Padding inside the QR code box
    const padding = -5;

    // context.fillStyle = "rgba(255, 0, 0, 0.3)"; // 0.3 for transparency
    context.fillStyle = isValid ? 'rgba(57, 255, 20, 0.8)' : 'rgba(255, 0, 0, 0.8)'; // Neon green with 30% opacity (0.3)

    // Set the stroke color to red for the box outline
    context.strokeStyle = isValid ? 'rgba(57, 255, 20, 0.8)' : 'rgba(255, 0, 0, 0.8)'; // Neon green with 30% opacity (0.3)
    context.lineWidth = 4;

    // Begin drawing the bounding box
    context.beginPath();
    context.moveTo(qrCode.location.topLeftCorner.x, qrCode.location.topLeftCorner.y);
    context.lineTo(qrCode.location.topRightCorner.x, qrCode.location.topRightCorner.y);
    context.lineTo(qrCode.location.bottomRightCorner.x, qrCode.location.bottomRightCorner.y);
    context.lineTo(qrCode.location.bottomLeftCorner.x, qrCode.location.bottomLeftCorner.y);
    context.closePath();
    
    // Fill the box with the red color
    context.fill();
    
    // Draw the red outline around the filled box
    context.stroke();

  }, [event?.users])

   //Function to draw the curved corner bounding box
   const drawWebRtcQRBoundingBox = useCallback((context: CanvasRenderingContext2D, canvas: HTMLCanvasElement) => {
    if (!context) return;

    Memory.QrData.forEach(qr => {
      // Convert normalized values to actual pixel values
      const {bounding_box: boundingBox} = qr
      const left = (boundingBox.Left * canvas.width);
      const top = (boundingBox.Top * canvas.height);
      const width = (boundingBox.Width * canvas.width);
      const height = (boundingBox.Height * canvas.height);
    
      // Set the fill style to neon green with 30% opacity
      context.fillStyle = 'rgba(57, 255, 20, 0.3)'; // Neon green with 30% opacity
      context.globalAlpha = 0.9; // Set opacity for the fill color
    
      // Draw a filled rectangle inside the bounding box
      context.fillRect(left, top, width, height);
    
      // Reset globalAlpha to fully opaque for future drawings
      context.globalAlpha = 1.0;
    })

  }, [])


  const drawCanvas = useCallback(async () => {
    const canvasCtx = canvasElement.current?.getContext("2d");
    const canvas = canvasElement.current;
    const video = videoElement.current;

    if (!canvasCtx || !video || !canvas) {
      return;
    }
    
    const radio = video.videoHeight / video.videoWidth;

    if (!videoWidth.current) {
      const el = document.querySelector('.left-section') ?? document.querySelector('.event-wrapper__center');
      videoWidth.current = (el?.clientWidth ?? 620) - 20;
      console.log('videoWidth.current', videoWidth.current)
      setVideoHeight(videoWidth.current * radio)
    }

    const drawingUtils = new DrawingUtils(canvasCtx);
    video.style.width = videoWidth.current + "px";
    video.style.height = videoWidth.current * radio + "px";
    canvas.style.width = videoWidth.current + "px";
    canvas.style.height = videoWidth.current * radio + "px";
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    // Now let's start detecting the stream.
    let startTimeMs = performance.now();
    if (lastVideoTime.current !== video.currentTime) {
      lastVideoTime.current = video.currentTime;
      faceLandmarkerResults.current = faceLandmarker.current?.detectForVideo(
        video,
        startTimeMs,
      );
      gestureRecognizerResults.current =
        gestureRecognizer.current?.recognize(video);
        frames.current = frames.current + 1;
        if (frames.current === frame_limit.current) {
          frames.current = 0;
          Memory.model?.detect(video).then(predictions => {
            predictionsResults.current = predictions;
            detectData.current.push(...predictions);
          })
        }
        
      if (qrDetect.current) {       
          // const ofCanvas = offlineCanvas.current
          // const context = ofCanvas.getContext('2d');
          // if (context) {
          //   // Set canvas size to match video size
          //   ofCanvas.width = video.videoWidth;
          //   ofCanvas.height = video.videoHeight;
  
          //   // Draw the current video frame onto the in-memory canvas
          //   context.drawImage(video, 0, 0, ofCanvas.width, ofCanvas.height);
          //   const imageData = context.getImageData(0, 0, ofCanvas.width, ofCanvas.height);
          //   const now = Date.now();
          //   if (now - lastScanTime > scanInterval) {
          //     lastScanTime = now;
          //     // new Promise((resolve) => {
          //     //   qrCodeResults.current = jsQR(imageData.data, ofCanvas.width, ofCanvas.height, {
          //     //     inversionAttempts: 'attemptBoth'
          //     //   });
          //     //   resolve(true);
          //     // })
          //      worker.postMessage({
          //       imageData,
          //       width: ofCanvas.width,
          //       height: ofCanvas.height,
          //     });
          //   }
          // }
        }
    }

    canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
    var text = FaceMetaInfo.getValue("hint");
    var fontSize = 40;
    var fontFamily = "Arial";
    canvasCtx.font = fontSize + "px " + fontFamily;
    var textWidth = canvasCtx.measureText(text as string).width;
    var canvasWidth = canvas.width;
    var canvasHeight = canvas.height;
    var xPosition = (canvasWidth - textWidth) / 2;
    var yPosition = fontSize + 20; // Position it just below the top edge of the canvas
    canvasCtx.save();
    canvasCtx.translate(canvasWidth / 2, canvasHeight / 2);
    canvasCtx.scale(-1, 1);
    canvasCtx.translate(-canvasWidth / 2, -canvasHeight / 2);
    canvasCtx.fillStyle = "yellow";
    canvasCtx.fillText(text as string, xPosition, yPosition);
    canvasCtx.restore();

    if (!gestureRecognizerResults.current?.landmarks?.length) {
      style.lineColor = "#fff";
      setShowPalmMatch(false);
    } else {
      setShowPalmMatch(true);
    }

    // if (Memory.QrData.length && !qrDetect.current) {
    //   drawWebRtcQRBoundingBox(canvasCtx, canvas);
    // }

    // // If a QR code is detected, draw a bounding box around it
    // if (qrDetect.current && qrCodeResults.current && canvasCtx) {
    //   drawQrBoundingBox(canvasCtx);
    // }
  
    for (const landmarks of gestureRecognizerResults.current?.landmarks ?? []) {
      drawPalmCircle(landmarks, style);

      // drawingUtils.drawConnectors(
      //   landmarks,
      //   GestureRecognizer.HAND_CONNECTIONS,
      //   {
      //     color: "#C0C0C070",
      //     lineWidth: 5
      //   }
      // );
      // drawingUtils.drawLandmarks(landmarks, {
      //   color: "#C0C0C070",
      //   lineWidth: 1
      // });
    }

    if (gestureRecognizerResults.current?.landmarks?.length) {
      drawPlaceholder(style);
    }
    // emit
    if (faceLandmarkerResults.current?.faceLandmarks) {
      for (const landmarks of faceLandmarkerResults.current.faceLandmarks ??
        []) {

        // Calculate bounding box
        const xValues = landmarks.map((landmark) => landmark.x * canvasWidth);
        const yValues = landmarks.map(
          (landmark) => landmark.y * canvasHeight
        );

        const minX = Math.min(...xValues);
        const maxX = Math.max(...xValues);
        const minY = Math.min(...yValues);
        const maxY = Math.max(...yValues);

        if (detectData.current.length >= 5) {
          const person = findLowestValueInObjects(detectData.current ?? [], 'person', 'score');
          // const cellPhone = findLowestValueInObjects(detectData.current ?? [], "cell phone", 'score');
          // const personScore = detectData.current?.find(p => p.class === 'person');
          
          // if (cellPhone && person) {
            Memory.detection.externalDevice = !!detectData.current?.find(p => /cell|remote/gi.test(p.class));
            // const isFaceInsideCellPhone = isPersonInsideCellPhone(cellPhoneBox.bbox, personBox.bbox, videoWidth);
            // console.log({
            //   // isFaceInsideCellPhone,
            //   cellPhoneBox,
            //   personBox,
            // })
          // }
          
          if (person?.score) {
            Memory.detection.isPersonScoreLow = person?.score < 0.40;
          }
          detectData.current = [];
        }
        if (Memory.webrtcConnected) {
          // if (Memory.detection.externalDevice) {
          //   FaceMetaInfo.updateValue('code', FaceMetaInfo.TYPES.s_spoof);
          //   FaceMetaInfo.updateValue('score', 0);
          //   setFace(0);
          // } else {
          //   FaceMetaInfo.updateValue('code', '');
          //   FaceMetaInfo.updateValue('score', 100);
          //   if (!Memory.detection.isPersonScoreLow && Memory.faceScore > 80) {
          //     setFace(Memory.faceScore);
          //   }
          // }
          
          // if (personScore) {
          //   if (Memory.detection.isPersonScoreLow) {
          //     FaceMetaInfo.updateValue('code', FaceMetaInfo.TYPES.s_spoof);
          //     FaceMetaInfo.updateValue('score', 0);
          //     setFace(0);
          //   } else if (!Memory.detection.isPersonScoreLow) {
          //     if (Memory.faceScore > 80) {
          //       setFace(Memory.faceScore);
          //     }
          //   }
          // }
  
          const textSoof = FaceMetaInfo.getValue("code") as string;
          
          drawSpoof(textSoof, canvasCtx, {
            minX, 
            maxX,
            maxY,
            minY,
          })
        }

        // sendImageToSocket({
        //   minX,
        //   minY,
        //   maxY,
        //   maxX,
        // });
        // Draw face landmarks
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_TESSELATION,
          { color: "#C0C0C070", lineWidth: 1 }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE,
          { color: "#C0C0C070" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_RIGHT_EYEBROW,
          { color: "#C0C0C070" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_LEFT_EYE,
          { color: "#C0C0C070" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_LEFT_EYEBROW,
          { color: "#C0C0C070" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_FACE_OVAL,
          { color: "#C0C0C070" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_LIPS,
          {
            color: "#C0C0C070",
          }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_RIGHT_IRIS,
          { color: "#C0C0C070" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_LEFT_IRIS,
          { color: "#C0C0C070" }
        );
      }
    }

    callbackFunc.current?.(
      faceLandmarkerResults.current,
      gestureRecognizerResults.current,
      predictionsResults.current,
    );

    if (videoBlendShapes.current) {
      drawBlendShapes(
        videoBlendShapes.current,
        faceLandmarkerResults.current?.faceBlendshapes ?? []
      );
    }

    // Call this function again to keep predicting when the browser is ready.
    if (webcamRunning.current === true) {
      window.requestAnimationFrame(drawCanvas);
    }
  }, [setVideoHeight,setShowPalmMatch, drawWebRtcQRBoundingBox, drawQrBoundingBox, drawPalmCircle, drawPlaceholder, drawBlendShapes]);

  const enableCam = useCallback(
    (draw?: boolean, deviceId?: string) => {

      if (palmMatch.current) {
        if (!gestureRecognizer.current) {
          console.log("Wait! faceLandmarker not loaded yet.");
          return;
        }
      }

      if (!hasGetUserMedia) {
        console.error("No camera access");
        return;
      }

      let initialSize = {
        width: { ideal: window.innerWidth },
        height: { ideal: window.innerHeight },
      };
      // getUsermedia parameters.
      let constraints: MediaStreamConstraints & { facingMode?: string } = {
        video: initialSize,
        facingMode: "user",
      };

      if (deviceId) {
        constraints = {
          video: { deviceId: { exact: deviceId } },
        }
      }

      // Activate the webcam stream.
      navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
        if (videoElement.current) {
          videoElement.current.srcObject = stream;
          webcamRunning.current = true;
          if (draw) {
            videoElement.current.addEventListener("loadeddata", () => {
              if (videoElement.current) {
                const qrscanner = new QrScanner(videoElement.current, (result) => { 
                  const qrValue = result?.data
                  const isValid = !!event?.users.find(user => user.sessionId === qrValue);
                  
                  Memory.showedQrs[qrValue] = isValid;
                  setQrShowed(prev=> {
                    const cloned = JSON.parse(JSON.stringify(prev));
                    cloned[qrValue] = isValid
                    return cloned;
                  });

                  // if (eventType === 'both') {
                  //   if (qrValue) {
                  //     setQrShowed({[qrValue]: isValid});
                  //   } else {
                  //     setQrShowed({});
                  //   }
                  // } else if (eventType === 'any') {
                  //   if (qrValue) {
                  //     setQrShowed(prev=> {
                  //       const cloned = JSON.parse(JSON.stringify(prev));
                  //       cloned[qrValue] = isValid
                  //       return cloned;
                  //     });
                  //   }
                   
                  // } else if(eventType === 'qr') {
                  //   if (qrValue) {
                  //     setQrShowed({[qrValue]: isValid});
                  //   } else {
                  //     setQrShowed({});
                  //   }
                  // }

                  // if (!result) {
                  //   return;
                  // }

                  if (isValid) {
                    document.body.classList.remove("invalid-qr");
                    document.body.classList.add("success-qr");    
                  } else {
                    document.body.classList.remove("success-qr");    
                    document.body.classList.add("invalid-qr");    
                  }
                }, {
               
                  highlightScanRegion:true,
                  highlightCodeOutline:true,
                  maxScansPerSecond: 144,
                })
                if (!isStarted) {
                  isStarted = true
                  qrscanner.start() 
                }
              }
              drawCanvas()
            });
          }
        }
      });
    },
    [drawCanvas, event]
  );

  // Before we can use HandLandmarker class we must wait for it to finish
  // loading. Machine Learning models can be large and take a moment to
  // get everything needed to run.
  const start = useCallback(
    async ({
      video,
      canvas,
      blendShapes,
      draw = true,
      deviceId,
      onResults,
      palm = true,
      numFaces = 1
    }: {
      video: HTMLVideoElement;
      canvas: HTMLCanvasElement;
      blendShapes?: HTMLElement | null;
      draw?: boolean;
      deviceId?: string;
      numFaces?: number;
      palm?: boolean;
      onResults?: (results?: vision.FaceLandmarkerResult) => void;
    }) => {
      setModelLoaded(false);
      videoElement.current = video;
      canvasElement.current = canvas;
      if (blendShapes) {
        videoBlendShapes.current = blendShapes;
      }

      callbackFunc.current = onResults;
      const filesetResolver = await FilesetResolver.forVisionTasks(
        "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm"
      );

      faceLandmarker.current = await FaceLandmarker.createFromOptions(
        filesetResolver,
        {
          baseOptions: {
            modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
            delegate: "GPU",
          },
          outputFaceBlendshapes: true,
          runningMode: "VIDEO",
          numFaces,
        }
      );

      palmMatch.current = palm

      if (palm) {
        gestureRecognizer.current = await GestureRecognizer.createFromOptions(
          filesetResolver,
          {
            baseOptions: {
              modelAssetPath:
                "https://storage.googleapis.com/mediapipe-models/gesture_recognizer/gesture_recognizer/float16/1/gesture_recognizer.task",
              delegate: "GPU",
            },
            numHands: 2,
            runningMode: "IMAGE",
          }
        );
      }

      setModelLoaded(true);
      enableCam(draw, deviceId);
    },
    [enableCam]
  );

  const draw = useCallback(async () => {
    await drawCanvas();
  }, [drawCanvas]);

  return { start, draw };
};
