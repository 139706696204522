import { useCallback, useEffect, useState } from "react";
import "./event-camera.scss";
import { useFace } from "hooks";
import { Memory } from "memory";
import usePeerConnection from "hooks/event-connect-rtc";
import { HOST_URL } from "event-modal";
import axios from "axios";
import { EventState, ModelLoadedState, SelectedDeviceIdState, VideoHeightState } from "states";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { FaceLandmarkerResult } from "@mediapipe/tasks-vision";

import { EventMatching } from "./events-match";
import { CameraSelector } from "devices";
import { Button } from "components";
import { checkCameraAccess } from "utils/camera";
import { CircularProgress } from "@mui/material";

export const EventCamera = () => {
  const [isCameraOn, setCameraOn] = useState(false);
  const setEvent = useSetRecoilState(EventState);
  const { start } = useFace();
  const { createPeerConnection } = usePeerConnection();
  const videoHeight = useRecoilValue(VideoHeightState);
  const selectedDeviceId = useRecoilValue(SelectedDeviceIdState);
  const [isCameraLoading, setIsCameraLoading] = useState(false);
  const [isCameraAccess, setIsCameraAccess] = useState(false);
  const modelLoaded = useRecoilValue(ModelLoadedState);

  const onResults = useCallback((results?: FaceLandmarkerResult) => {
    //
  }, []);

  // Classes for styling the canvas and webcam elements
  const canvasClass = "_output_canvas";
  const webcamClass = "_webcam";

  // Callback function to start the webcam and face detection

  const onCam = useCallback(() => {
    const videoBlendShapes = document.getElementById("video-blend-shapes");
    const video = document.getElementById(webcamClass) as HTMLVideoElement;
    const canvasElement = document.getElementById(
      canvasClass
    ) as HTMLCanvasElement;

    start({
      video,
      canvas: canvasElement,
      blendShapes: videoBlendShapes,
      deviceId: selectedDeviceId,
      onResults,
      numFaces: 10,
      palm: false,
    }).then(() => {
      setCameraOn(true);
      //   setWebcamOpen(true);
    });
  }, [onResults, selectedDeviceId, start]);

  const onClick = useCallback(async () => {
    if (!Memory.connectionMade) {
      createPeerConnection();
    }
    const url = `${HOST_URL}/pipelines`;
    axios
      .get(`${url}/events/${Memory.eventId}?type=event`)
      .then(({ data }) => {
        const eventData = data?.data;
        if (eventData) {
          setEvent(eventData);
        }
        console.log("sadasd", eventData);
        //   setQrs(eventData?.qrIds ?? []);
      })
      .catch((err) => {
        console.error("event err", err);
      });
    onCam();
  }, [createPeerConnection, onCam, setEvent]);

  const Camera = useCallback(() => {
    return (
      <>
        <div>
          <div id="liveView" className="videoView">
            <div
              style={{
                position: "relative",
              }}
            >
              <video
                id={webcamClass}
                style={{
                  position: "absolute",
                }}
                autoPlay
                playsInline
              ></video>
              <canvas
                className={canvasClass}
                id={canvasClass}
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                }}
              ></canvas>
            </div>
          </div>
        </div>
      </>
    );
  }, []);

  const CheckingCameraAccess = async () => {
    setIsCameraLoading(true);
    const resp = await checkCameraAccess();
    setIsCameraLoading(false);
    setIsCameraAccess(resp);
  };

  useEffect(() => {
    CheckingCameraAccess();
  }, []);

  const CameraSettingsOffMessages = {
    Heading: "Camera access required",
    subHeading:
      "It looks like your camera is not enabled, or we do not have permission to access it.",
    instructionOne:
      "Ensure your camera is physically connected(in case of external webcam) and turned on.",
    instructionTwo: "open your browser settings and allow camera permissions.",
  };

  if (isCameraLoading) {
    return <div
    style={{
      minHeight: videoHeight + 20,
      display: "grid",
      placeItems: "center",
    }}
  >
    <div style={{display: 'flex', alignItems: 'center', gap: "10px"}}>Camera Loading... <CircularProgress /></div>
  </div>;
  }
  if (!isCameraAccess) {
    return (
      <div
        style={{
          minHeight: videoHeight + 20,
          display: "grid",
          placeItems: "center",
        }}
      >
        <div className="settings__wrapper">
          <img
            src="https://storage.googleapis.com/satschel-public-assets/images/kyc-frontend-media/cameraSettings.svg"
            alt="camera"
          />
          <p className="settings__wrapper-heading">
            {CameraSettingsOffMessages.Heading}
          </p>
          <p className="settings__wrapper-subheading">
            {CameraSettingsOffMessages.subHeading}
          </p>
          <div className="settings__instuction-notes-box">
            <p>Follow these steps:</p>
            <ul>
              <li>{CameraSettingsOffMessages.instructionOne}</li>
              <li>{CameraSettingsOffMessages.instructionTwo}</li>
            </ul>
          </div>
          <Button
            label={"Refresh"}
            handleClick={() => window.location.reload()}
            type="button__filled button__filled--primary button__large "
          />
        </div>
        ;
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          minHeight: videoHeight + 20,
        }}
      >
        <div>
          <Camera />
        </div>
      </div>
      <div
        style={{
          minWidth: "800px",
          padding: "0px 16px",
        }}
      >
        {isCameraOn ? (
          <EventMatching />
        ) : (
          <div className="center-align">
          {/* Camera selector component to choose between available cameras */}
            <div className="d-flex align-item-center direction-column space-around gp-8 p-16  w-480">
              { modelLoaded === false ?  
              <div style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "inherit"
              }}>
                <CircularProgress /> 
                <div>Please wait, we are loading the model</div>
              </div>: 
                <>
                  <CameraSelector />
                  {/* Button to trigger the onCam function */}
                  <Button
                    handleClick={onClick}
                    label={"Enable Webcam"} // Dynamically updating the button label
                    type="button__filled button__filled--primary button__large w-100"
                  />
                </>
                }
            </div>
          </div>
        )}
      </div>
    </>
  );
};
