import React, { useEffect } from "react";
import "./App.css";
import { Facial } from "./facial";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { GetQueryParams, GetUrlType } from "./utils";
import { Home } from "./home";
import { Enroll } from "./enroll";
import { Demo } from "./demo";
import { Event } from "./events";
import usePeerConnection from "./hooks/connect-rtc";
import useEventPeerConnection from "./hooks/event-connect-rtc";
import { getSessionDetailByPhone } from "./modal";
import { Memory } from "./memory";
import { useModel } from "./hooks";
import axios from "axios";
import { useSetRecoilState } from "recoil";
import { AttendedState, EventState, EventTypeState, IEvent, QrState } from "./states";
import { HOST_URL } from "./event-modal";
import { Events } from "views";
import { Qr } from "views/qr";

export const worker = new Worker("./worker.js");

function App() {
  const { createPeerConnection } = usePeerConnection();
  const { createPeerConnection: createEventPeerConnection } =
    useEventPeerConnection();
  const setQrs = useSetRecoilState(QrState);
  const setEvent = useSetRecoilState(EventState);
  const setEventType = useSetRecoilState(EventTypeState)
  const setAttended = useSetRecoilState(AttendedState);

  const { loadModel } = useModel();

  // const uuId = GetQueryParams("id");
  // const urlType = GetUrlType(window.location.href);

  // https://biometrics.pp.satschel.com/recipient?id=65280179f50680ddb6567447
  // https://biometrics.pp.satschel.com/sender?id=65280179f50680ddb6567447
  //window.location.href

  useEffect(() => {
    loadModel();
    const search = window.location.search;
    if (search) {
      const params = new URLSearchParams(search);
      const phone = params.get("phone");
      const rec = params.get("rec");
      const frames = params.get("frames");
      const frames_limit = params.get("frames_limit");
      const timeout = params.get("timeout");
      const eventId = params.get("eventId");
      const connect = params.get("connect");

      if (frames_limit) {
        Memory.frames_limit = Number(frames_limit);
      }

      if (frames) {
        Memory.frames = Number(frames);
      }
      if (rec) {
        Memory.match = rec === "true";
      }
      if (timeout) {
        Memory.timeout = Number(timeout);
      }
      if (eventId) {
        const url = `${HOST_URL}/pipelines`;
        axios
          .get(`${url}/events/${eventId}?type=event`)
          .then(({ data }) => {
            const eventData: IEvent = data?.data;
            if (eventData) {
              setEvent(eventData);
              const attended: Record<string, boolean> = {}
              eventData.users.forEach(user => {
                if (user.attendedAt) {
                  attended[user.sessionId] = true;
                }
              })

              setAttended(attended);
              setEventType(eventData.type ?? 'both')
              Memory.eventUsers = eventData.users;
            }
            console.log("sadasd", eventData);
            setQrs(eventData?.qrIds ?? []);
          })
          .catch((err) => {
            console.error("event err", err);
          });
        Memory.eventId = eventId;
      }

      if (connect) {
        createEventPeerConnection();
      }
      if (phone) {
        const result = { phone: phone };
        console.log(result);
        if (result.phone === "4157676179") {
          createPeerConnection({} as any);
        } else {
          getSessionDetailByPhone(phone).then((session) => {
            if (session) {
              createPeerConnection(session);
              // setSessionDetail(session);
            }
          });
        }
      }
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path={"/"} element={<Home />} />
          <Route path={"/demo"} element={<Demo />} />
          <Route path={"/session-events"} element={<Event />} />
          <Route path={"/events"} element={<Events />} />
          <Route path={"/qr"} element={<Qr />} />
          {/* <Route path={"/enroll/:id"} element={<Enroll />} /> */}
          {/* <Route
            path="/:id"
            element={<Facial  />}
          /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
