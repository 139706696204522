import { Fragment, useCallback, useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { EventHeader } from "../event-header";
import { ProfileSection } from "../profile/profile";
import { AttendedState, EventState, EventTypeState, IEvent, QrState, SessionInfoState } from "states"; // Importing Recoil state atoms
import { HOST_URL } from "event-modal";
import axios from "axios";
import { Memory } from "memory";
import { TAG_MAP } from "views/events/constants";

export const MatchedProfile = () => {
  // Using Recoil to get session data and selected device ID from global state
  const sessions = useRecoilValue(SessionInfoState);
  const event = useRecoilValue(EventState);
  const qrs = useRecoilValue(QrState);
  const eventType = useRecoilValue(EventTypeState);
  const setAttended = useSetRecoilState(AttendedState);

  const updateEvent = useCallback(async (users: IEvent['users']) => {
		try {
		  const eventId = qrs?.[0]?._id;
		  // SET V2
		  const HOST = `${HOST_URL}/pipelines`
		  const {data} = await axios.patch(`${HOST}/events/${eventId}`, {users});
		  console.log("data", data)
		} catch(err) {
		  console.log("err", err);
		}
	}, [qrs])

  const attend = useCallback(async () => {
    const eventUsers: IEvent['users'] = JSON.parse(JSON.stringify(event?.users ?? []));
    console.log('eventUsers', eventUsers);
    for (const sessionId of Object.keys(sessions)) {
      const qrFound = Memory.showedQrs[sessionId];

      if (['any', 'both', 'qr'].includes(eventType) && !qrFound) {
        continue;
      }
      
      if (Memory.attended[sessionId]) {
        continue
      }

      Memory.attended[sessionId] = true;
      const foundUser = eventUsers.find((user) => user.sessionId === sessionId);
      console.log('foundUser', foundUser);
    
      if (!foundUser) {
        console.error("user not found");
        continue;
      }
    
      const attendedAt = new Date().toISOString();
      foundUser.attendedAt = attendedAt;
      console.log('attendedAt', attendedAt, foundUser)
      setAttended(prev=> {
        const cloned = JSON.parse(JSON.stringify(prev));
        cloned[sessionId] = true
        return cloned;
      });
      await updateEvent(eventUsers);
    }
  }, [event?.users, eventType, sessions, setAttended, updateEvent])
  
  useEffect(() => {
    attend();
  }, [sessions])

  // useMemo to memoize the transformation of session data into event users
  const matchedUsers = useMemo<IEventUser[]>(() => {
    const eventUsers: IEventUser[] = Object.keys(sessions).map((sessionId) => {
      const session = sessions[sessionId];
      // Destructure session properties
      const { _id, image, name, phone, email, info, status, attendedAt, ticketNumber, metadata, createdAt } = session;
      // Create an eventUser object with required fields
      
      const tag = TAG_MAP[status] ?? status;
      const eventUser: IEventUser = {
        _id,
        email,
        img: image,
        sessionId,
        name,
        seat: metadata?.seat,
        row: metadata?.row,
        phone,
        ticketNumber,
				date: attendedAt ?? createdAt,
        tags: [tag, 'Club Seats'], // Assuming no tags are provided
        isSpoof: info.is_spoof, // Using isSpoof from session info
        similarity: info.similarity,
        verified: info.verified,
        qrShowed: info.qrShowed,
      };
      return eventUser; // Return each user
    });

    return eventUsers; // Return the list of matched event users
  }, [sessions]); // Recompute when sessions change

  return (
    <Fragment>
      {/* Header for the matched tickets section */}
      <EventHeader label="Matched Tickets" className="pl-16 pr-16" />
      <div className="event-wrapper__matched-profiles">
        {/* Display each matched user using the ProfileSection component */}
        {matchedUsers.map((el) => (
          <ProfileSection
            type='Attended'
            from='matched'
            className="p-8 event-matched-profile"
            key={el.email} // Use email as a unique key for rendering
            {...el} // Spread all event user properties to ProfileSection
          />
        ))}
      </div>
    </Fragment>
  );
};
